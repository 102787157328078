/* eslint-disable react/display-name */
import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

import {
  Wrapper,
  Figure,
  Title,
  SubTitle,
  Url,
  Tag,
  Navigation,
  Arrow,
  Label,
  Timer,
  Content,
} from '../styles/styled_blogpost';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { main } from '../../config/theme';
import SocialMedia from '../components/social_media';

const BlogPost = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  const image = post.frontmatter.cover
    ? post.frontmatter.cover.childImageSharp
    : null;
  const { nextPost, prevPost } = pageContext;
  const { prevUrl } = location.state || '';

  let Cover;
  if (image) {
    Cover = <GatsbyImage image={image.gatsbyImageData} alt="headline image" />;
  }

  return (
    <Layout>
      <SEO
        title={`${post.frontmatter.title} - Repodev`}
        description={post.excerpt || post.frontmatter.description || ' '}
        pathname={post.frontmatter.path}
        image={image}
        article
      />
      <Wrapper xl={8} lg={8} md={10} sm={11} className="article-page mx-auto">
        <main className="main" style={main}>
          <div className="post template">
            {/* -- start main content -- */}
            <Figure className="article figure">
              <Title className="title">
                {post.frontmatter.title}
                <SubTitle className="sub">{post.frontmatter.date}</SubTitle>
                <Url
                  className="category link"
                  to="/"
                  onClick={(e) => e.preventDefault()}
                >
                  written by {post.frontmatter.author}
                </Url>
                <Url
                  className="category back"
                  to={prevUrl ? prevUrl : '/blog'}
                  style={{
                    float: 'right',
                    color: '#000',
                    backgroundColor: '#fff',
                    marginTop: '0.57em',
                  }}
                  aria-label="Back to previous page"
                >
                  <i className="fas fa-list"></i>
                </Url>
              </Title>

              {/* TODO: optimize me */}

              <div
                className="d-flex justify-content-center"
                style={{ marginBottom: '1.5rem' }}
              >
                {Cover}
              </div>

              <Content>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
              </Content>
            </Figure>
            {/* -- end main content -- */}

            {/* -- start footer content -- */}
            {post.frontmatter.tags.length !== 0 && (
              <Figure
                className="post tags figure"
                style={{
                  fontWeight: 'normal',
                  fontKerning: 'normal',
                  boxSizing: 'border-box',
                  marginTop: '0.2em',
                }}
              >
                {post.frontmatter.tags.map((tag) => (
                  <Tag key={tag} className="tag button" to={`/blog/tag/${tag}`}>
                    {tag}
                  </Tag>
                ))}
              </Figure>
            )}

            <Figure
              className="post navigation figure"
              style={{ marginTop: '0.83333em' }}
            >
              {prevPost && (
                <Navigation
                  className="link previous"
                  to={prevPost.fields.slug}
                  title={prevPost.frontmatter.title}
                  style={{ marginLeft: '1.5em' }}
                  state={{ prevUrl }}
                >
                  <Arrow
                    className="arrow left"
                    style={{
                      left: ' -1.5em',
                      transform: 'translate3d(0, -50%, 0) rotate(-135deg)',
                    }}
                  ></Arrow>
                  <Label
                    className="label"
                    style={{
                      right: '-0.5em',
                    }}
                  >
                    previous
                  </Label>
                  {prevPost.frontmatter.title.length > 10
                    ? prevPost.frontmatter.title.substring(0, 10) + '...'
                    : prevPost.frontmatter.title}
                </Navigation>
              )}

              {nextPost && (
                <Navigation
                  className="link next"
                  to={nextPost.fields.slug}
                  title={nextPost.frontmatter.title}
                  style={{
                    float: 'right',
                    marginRight: '1.5em',
                    paddingTop: '6px',
                  }}
                  state={{ prevUrl }}
                >
                  <Arrow
                    className="arrow right"
                    style={{
                      right: '-1.5em',
                      paddingTop: '7px',
                      transform: 'translate3d(0, -50%, 0) rotate(45deg)',
                    }}
                  ></Arrow>
                  <Label
                    className="label"
                    style={{
                      top: '-1em',
                      paddingTop: '6px',
                      right: 'auto',
                      left: '-0.5em',
                    }}
                  >
                    next
                  </Label>
                  {nextPost.frontmatter.title.length > 10
                    ? nextPost.frontmatter.title.substring(0, 10) + '...'
                    : nextPost.frontmatter.title}
                </Navigation>
              )}
            </Figure>

            <Timer className="post reading time figure">
              <p className="caption">
                <small className="label">Reading Time</small>
                {post.timeToRead} min
              </p>
            </Timer>

            {/* TODO: Enable all social media share buttons functionality */}
            <SocialMedia url={location.href} title={post.frontmatter.title} />
          </div>
        </main>
      </Wrapper>
    </Layout>
  );
};

export default BlogPost;
BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    prevPost: PropTypes.object,
    nextPost: PropTypes.object,
  }),
  location: PropTypes.any.isRequired,
};

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      id
      fields {
        slug
      }
      excerpt
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        tags
        path
        author
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      timeToRead
    }
  }
`;
